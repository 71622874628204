import React from "react"

import { Contact, Layout } from "../components"
import Seo from "../components/seo"

const ContactPage = () => {
  return (
    <Layout>
      <Seo
        title="Thai restaurant in Montpelier, Vermont Google Map and direction."
        description="Pho Thai Express restaurant Google Map and direction."
      />
      <main>
        <Contact />
      </main>
    </Layout>
  )
}

export default ContactPage
